<template>
  <div class="min-h-screen bg-gray-background">
    <adminHeader></adminHeader>
    <main class="">
      <sideMenu></sideMenu>
      <transition name="fade">
        <router-view class="router_view"></router-view>
      </transition>
      <vue-progress-bar></vue-progress-bar>
    </main>
    <div id="fb-root"></div>
  </div>
</template>

<script>
import adminHeader from '@/components/header.vue'
import sideMenu from '@/components/sideMenu.vue'

export default {
  components: {
    adminHeader,
    sideMenu,
  },
  data() {
    return {
    }
  },
  watch: {
    $route(to, from) {
      // 若在手機選單上跳轉頁面時，手機選單關閉
      if (this.$store.state.mobile_menu) this.$store.dispatch('closeOverlay')
    },
  },
  created() {
  },
  methods: {
    // createFbChat(scope) {},
  },
  mounted() { 
  }
}
</script>

<style lang="scss" scoped>
.router_view {
  @apply pt-20 pb-20 px-3 lg:px-5
  bg-gray-background
  relative
  text-sm text-gray-text_light;

  width: 100%;
  height: 100%;

  @screen lg {
    width: calc(100% - 260px);
    // height: calc(100% - 12px);
    padding-top: 5px;
    left: 260px;
    top: 64px; // header的高度
  }
}
</style>
