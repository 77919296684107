<template>
  <nav
    class="h-full bg-white flex flex-col text-gray-text_dark text-sm font-medium overflow-y-auto transform translate-x-0 rounded-r-xl"
    :style="{ transform: mobile_menu ? 'translateX(0)' : '' }"
  >
    <router-link :to="{ name: 'admin' }">
      <div class="block lg:hidden px-3 pt-3">
        <img src="@/assets/images/logo.png" alt="Logo" />
      </div>
    </router-link>
    <div class="flex-grow bg-white w-full divide-y-2 divide-gray-background">
      <!-- main menu -->
      <section class="pb-5 pt-0 flex flex-col space-y-1 mt-5">
        <div class="menu" v-for="(item, index) in main_menu" :key="item.title">
          <!-- 若為有subMenu的選單 -->
          <button v-if="item.subMenu.length > 0" @click="open_sub_menu(index, item.routerName)" class="menu_title">
            <svg-icon :icon-class="item.iconName" className="gray-dark"></svg-icon>
            <span>{{ item.title }}</span>
          </button>

          <!-- 只有一層的選單 -->
          <router-link @click.native="close_sub_menu" v-else :to="{ name: item.routerName }" class="menu_title">
            <svg-icon :icon-class="item.iconName" className="gray-dark"></svg-icon>
            <span>{{ item.title }}</span>
          </router-link>

          <div class="sub_menu" :class="[opening_sub_menu === index ? 'h-auto' : 'h-0']">
            <router-link v-for="subItem in item.subMenu" :key="subItem.title" :to="{ name: subItem.routerName }">
            {{ subItem.title }}
            </router-link>
          </div>
        </div>
      </section>
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      main_menu: [
        // {
        //   title: '平台管理',
        //   iconName: 'websetting',
        //   routerName: 'platform_management',
        //   subMenu: [
        //     {
        //       title: '首頁設定',
        //       routerName: 'home_setting',
        //     },
        //     {
        //       title: '創立目的',
        //       routerName: 'founding_purpose',
        //     },
        //     {
        //       title: '團隊介紹',
        //       routerName: 'team_introduction',
        //     },
        //     {
        //       title: '什麼是二房東',
        //       routerName: 'landlord',
        //     },
        //   ],
        // },
        // {
        //   title: '物件管理',
        //   iconName: 'room',
        //   routerName: 'room_management',
        //   subMenu: [
        //     {
        //       title: '物件列表',
        //       routerName: 'rooms',
        //     },
        //     {
        //       title: '房型樣式',
        //       routerName: 'rstyles',
        //     },
        //     {
        //       title: '房間設備',
        //       routerName: 'rdevices',
        //     },
        //     {
        //       title: '設備服務',
        //       routerName: 'rservices',
        //     },
        //     {
        //       title: '其他需求',
        //       routerName: 'rneeds',
        //     },
        //   ],
        // },
        // {
        //   title: 'QA管理',
        //   iconName: 'question',
        //   routerName: 'qa_management',
        //   subMenu: [
        //     {
        //       title: 'Q&A列表',
        //       routerName: 'qas',
        //     },
        //   ],
        // },
        // {
        //   title: '報表管理',
        //   iconName: 'report',
        //   routerName: 'report_management',
        //   subMenu: [
        //     {
        //       title: '瀏覽數',
        //       routerName: 'page_view',
        //     },
        //     {
        //       title: '業務報表',
        //       routerName: 'sales_report',
        //     },
        //   ],
        // },
        // {
        //   title: '權限管理',
        //   iconName: 'roles',
        //   routerName: 'role_management',
        //   subMenu: [
        //     {
        //       title: '部門列表',
        //       routerName: 'roles',
        //     },
        //     {
        //       title: '員工列表',
        //       routerName: 'users',
        //     },
        //   ],
        // },
      ],
      opening_sub_menu: '',
    }
  },
  created() {
    this.main_menu = JSON.parse(this.$store.state.login.funcs);
  },
  computed: {
    mobile_menu() {
      return this.$store.state.mobile_menu
    },
  },
  watch: {
    $route: {
      handler(route) {
        let index = route.meta.sideMenuIndex
        this.open_sub_menu(index)
      },
      immediate: true,
    },
  },

  methods: {
    open_sub_menu(index, routerName) {
      // if (!index) return
      // 儲存打開中的選單，重整後預設打開
      // localStorage.opening_submenu_index = index
      this.opening_sub_menu = index

      if (!routerName) return

      // 若視窗大小 >= 1024 就直接跳轉
      if (parseInt(document.body.clientWidth, 10) >= 1024) {
        this.$router
          .push({
            name: routerName,
          })
          .catch((err) => {})
      }
    },
    close_sub_menu() {
      this.opening_sub_menu = ''
      // localStorage.opening_submenu_index = ''
    },
  },
}
</script>

<style lang="scss" scoped>
nav {
  position: fixed;
  width: 260px;
  // top: calc(56px + 20px);
  top: 64px; // header的高度
  height: calc(100vh - 64px);
  @media (max-width: 1024px) {
    z-index: 3000;
    position: fixed;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    height: 100vh;
    @apply transition-transform duration-500 ease-in-out;
  }
}

.menu {
  @apply w-full pr-4 space-y-1;
}

.menu_title {
  @apply w-full space-x-2 text-left flex items-center pl-7 h-8 rounded-r-md hover:bg-gray-background transition-colors relative;

  &::after {
    content: '';
    background: var(--success);
    width: 5px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }

  &.router-link-active {
    @apply bg-blue-50 text-success;

    &::after {
      transform: translateX(0);
    }
  }
}

.sub_menu {
  @apply flex flex-col overflow-hidden space-y-1;

  a {
    @apply h-8 flex items-center hover:bg-gray-background transition-colors  rounded-r-md relative text-gray-400;
    padding-left: 58px;

    &::after {
      content: '';
      background: var(--success);
      width: 5px;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      transform: translateX(-100%);
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      transition: transform 0.3s ease-in-out;
    }

    &.router-link-active {
      @apply bg-blue-50 text-success;

      &::after {
        transform: translateX(0);
      }
    }
  }
}

button {
  @apply font-medium;
}

.overview_section_button_left {
  @apply flex items-center justify-start px-1 space-x-2 h-full w-fit rounded-md hover:bg-white transition-colors;
}
.overview_section_button_right {
  @apply flex items-center justify-start px-1 space-x-2 h-full w-fit rounded-md hover:bg-white transition-colors;
}
</style>
