<template>
  <div>
    <header class="w-full bg-gray-background h-16 py-2 px-3 flex items-center justify-between text-gray-text_dark z-1000">
      <router-link :to="{ name: 'admin' }">
        <section style="width: calc(260px - 12px)" class="pr-3 hidden lg:block relative">
          <img src="@/assets/images/logo.png" alt="Logo" />
        </section>
      </router-link>
      <!-- 手機版漢堡按鈕 -->
      <button @click="open_mobile_menu" class="lg:hidden h-full">
        <svg-icon icon-class="hamburger" className="gray-dark" style="width: 30px"></svg-icon>
      </button>

      <!-- 標題 -->
      <section class="pl-4 flex-grow">
        <h1 class="text-lg font-medium lg:hidden">{{ $route.meta.title }}</h1>
      </section>

      <section class="relative h-full flex-shrink-0">
        <button
          id="account_btn"
          @click.stop="toggle_account_menu"
          class="h-full hover:bg-white rounded-xl px-2 flex items-center space-x-2 group transition-colors"
          :class="{ 'bg-white': account_menu }"
        >
          <div class="bg-gray-divide rounded-full h-8 w-8 flex items-center justify-center">
            <svg-icon icon-class="account" style="stroke: white"></svg-icon>
          </div>
          <span class="hidden sm:block">{{ $store.state.login.uname }}</span>
        </button>

        <div
          class="absolute top-14 right-0 w-max rounded-xl bg-white p-2 flex-col flex shadow-md transition-all duration-200 z-1000 space-y-1"
          :class="[account_menu ? 'visible opacity-100 transform -translate-y-0' : 'opacity-0 invisible transform -translate-y-2']"
        >
          <router-link :to="{ name: 'account' }" class="account_menu--btn">
            <svg-icon icon-class="account" className="sm" style="stroke: currentColor"></svg-icon>
            <span>帳號設定</span>
          </router-link>
          <button @click="logout" class="account_menu--btn">
            <svg-icon icon-class="logout" className="sm"></svg-icon>
            <span>登出</span>
          </button>
        </div>
      </section>
    </header>
  </div>
</template>

<script>
export default {
  data() {
    return {
      account_menu: false,
    }
  },
  mounted() {
    // 監聽點擊，實現點擊account menu之外的地方，關閉account_menu
    document.addEventListener('click', this.mouseClick)
  },
  // 銷毀自定義的監聽
  beforeDestroy() {
    document.removeEventListener('click', this.mouseClick)
  },
  methods: {
    // 開啟手機版選單
    open_mobile_menu() {
      this.$store.commit('MOBILE_MENU_TOGGLER', {
        name: 'MOBILE_MENU_TOGGLER',
      })
      this.$store.commit('OVERLAY_TOGGLER', true)
    },
    // account_menu開關
    toggle_account_menu() {
      this.account_menu = !this.account_menu
    },
    // 被監聽的點擊事件
    mouseClick(e) {
      if (this.account_menu && e.target.id !== 'account_btn') this.account_menu = false
    },
    logout() {
      this.$store.commit('login/LOG_OUT')
      this.$router.replace({
        name: 'login',
      })
    },
  },
}
</script>

<style scoped>
header {
  position: fixed;
}

.account_menu--btn {
  @apply p-2 rounded-xl hover:bg-gray-background flex items-center justify-start space-x-3 transition-colors;
}
</style>
